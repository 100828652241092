// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CheckoutForm.css */
.checkout-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f6f6f6;
  box-shadow: 0 0 10px rgba(196, 29, 29, 0.1);
}

.form-row {
  margin-bottom: 20px;
}

.label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
}

.card-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: cover;
  margin-right: 10px;
}

.submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.product-image-div{
  width: 100%;
  height: 300px;
}
.product-image-div img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.prod-details-btn {
  display: flex;
  justify-content: space-between;
}
.zoom-effect-container img {
  transition: transform 0.5s ease;
}

.zoom-effect-container:hover img {
  transform: scale(1.2); /* Adjust the scale value as needed */
}`, "",{"version":3,"sources":["webpack://./src/css/style.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,2CAA2C;AAC7C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,aAAa;AACf;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,+BAA+B;AACjC;;AAEA;EACE,qBAAqB,EAAE,qCAAqC;AAC9D","sourcesContent":["/* CheckoutForm.css */\n.checkout-form {\n  width: 100%;\n  max-width: 400px;\n  margin: 0 auto;\n  padding: 20px;\n  border-radius: 8px;\n  background-color: #f6f6f6;\n  box-shadow: 0 0 10px rgba(196, 29, 29, 0.1);\n}\n\n.form-row {\n  margin-bottom: 20px;\n}\n\n.label {\n  font-size: 16px;\n  font-weight: bold;\n  margin-bottom: 8px;\n  display: block;\n}\n\n.card-icon {\n  display: inline-block;\n  width: 30px;\n  height: 30px;\n  background-size: cover;\n  margin-right: 10px;\n}\n\n.submit-button {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  padding: 10px 20px;\n  border-radius: 4px;\n  cursor: pointer;\n}\n\n.submit-button:disabled {\n  background-color: #ccc;\n  cursor: not-allowed;\n}\n.product-image-div{\n  width: 100%;\n  height: 300px;\n}\n.product-image-div img{\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n.prod-details-btn {\n  display: flex;\n  justify-content: space-between;\n}\n.zoom-effect-container img {\n  transition: transform 0.5s ease;\n}\n\n.zoom-effect-container:hover img {\n  transform: scale(1.2); /* Adjust the scale value as needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
