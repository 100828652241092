// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
    max-width: 400px;
    margin: 0 auto;
  }
  /* Style for the form */
  .payment-form {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  /* Style for the label */
  .payment-form label {
    display: block;
    margin-bottom: 10px;
  }
  /* Style for the card element */
  .payment-form .CardElement {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .payment-form .CardElement.cardNumber{
    
  }
  /* Style for the button */
  .payment-form button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  /* Style for the error message */
  .payment-form .error-message {
    color: #DC3545;
    margin-top: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/component/payment_stripe/stripe.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;EAChB;EACA,uBAAuB;EACvB;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;EACA,wBAAwB;EACxB;IACE,cAAc;IACd,mBAAmB;EACrB;EACA,+BAA+B;EAC/B;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;EACA;;EAEA;EACA,yBAAyB;EACzB;IACE,cAAc;IACd,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;EACA,gCAAgC;EAChC;IACE,cAAc;IACd,gBAAgB;EAClB","sourcesContent":[".form-container {\n    max-width: 400px;\n    margin: 0 auto;\n  }\n  /* Style for the form */\n  .payment-form {\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  /* Style for the label */\n  .payment-form label {\n    display: block;\n    margin-bottom: 10px;\n  }\n  /* Style for the card element */\n  .payment-form .CardElement {\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  .payment-form .CardElement.cardNumber{\n    \n  }\n  /* Style for the button */\n  .payment-form button {\n    display: block;\n    width: 100%;\n    padding: 10px;\n    margin-top: 20px;\n    background-color: #007BFF;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  /* Style for the error message */\n  .payment-form .error-message {\n    color: #DC3545;\n    margin-top: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
